$(document).ready(function () {

    init();

});


function init() {

    $('.js-action-scroll').click(function (e) {
        e.preventDefault()
        $('html, body').animate({
            scrollTop: $('.container').offset().top
        }, 1000);
    });

}





